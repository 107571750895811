import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { EmailSubscribeFormComponent } from './email-subscribe-form-component'

export const EmailSubscribeForm = (props) => (
  <StaticQuery
    query={graphql`
      query emailSubscribeFormQuery {
        site {
          siteMetadata {
            awsApiGatewayUrl
          }
        }
      }
    `}
    render={data =>
      <EmailSubscribeFormComponent
        apiUrl={data.site.siteMetadata.awsApiGatewayUrl}
        {...props} 
      />
    }
  />
)