import styled from 'styled-components'
import { media } from '../../../constants/media'

export const TextInput = styled.input.attrs({
  type: 'text',
})`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.nori};
  background: none;
  border: none;
  outline: none;
  border-bottom: 4px dotted ${props => props.theme.colors.nori};
  width: 100%;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.4rem;
  @media ${media.medium.query} {
    font-size: 2.2rem;
    line-height: 1.6rem;
  }
`;