import styled from 'styled-components'
import { media } from '../../../constants/media'

export const Button = styled.button.attrs({
  type: 'button'
})`
  font-family: ${props => props.theme.fonts.poppins};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border: none;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.salmon};
  cursor: pointer;

  width: 100%;
  height: 5.2rem;
  font-size: 1.7rem;
  line-height: 2.4rem;
  letter-spacing: 0.1rem;
  border-radius: 10px;

  @media ${media.medium.query} {
    height: 7.5rem;
    font-size: 2.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.16rem;
  }

  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.85;
  }
`;