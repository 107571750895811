import { byAlpha2 } from 'iso-country-codes'

export const getCountryNameByIsoCode = (code) => {
  const isoCountry = byAlpha2[code];
  if (isoCountry) {
    return isoCountry.altName || isoCountry.name;
  }
  return null;
}

export const getCountryName = (countryObj) => {
  return countryObj.ShortName || countryObj.Name;
}

export const convertCountriesToString = (arr) => {
  if (Array.isArray(arr)) {
    const firstCountryName = getCountryName(arr[0]);

    if (arr.length > 1) {
      return arr.slice(1).reduce((acc, cur) =>
        `${acc}, ${getCountryName(cur)}`, firstCountryName);
    }

    return firstCountryName;
  }

  return '';
}