import React from 'react'

import * as S from './loading-spinner-styled'

export const LoadingSpinner = () => (
  <S.Container>
    <S.Ellipsis>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </S.Ellipsis>
  </S.Container>
)