import { useState } from 'react'
import { isValidEmail } from '../../../helpers/email/email'
import { makeSubscribeEmailRequest } from './email-subscribe-form-networking'

export const useEmailSubscribeForm = ({ apiUrl }) => {
  const [emailInputText, setEmailInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasSuccessfullySubscribed, setHasSuccessfullySubscribed] = useState(false);

  const handleSubscribeClick = () => {

    if (emailInputText && isValidEmail(emailInputText) && !isLoading) {

      setIsLoading(true);
      setEmailInputText('');

      makeSubscribeEmailRequest({ apiUrl, email: emailInputText })
        .then(response => response.json())
        .then(response => {
          if (response.error) {
            throw new Error(response.error);
          } else {
            setIsLoading(false);
            setErrorMessage('');
            setHasSuccessfullySubscribed(true);
          }
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
          setErrorMessage('An error occurred.');
        })

    } else {

      setErrorMessage('Invalid Email.');

    }
  }

  return {
    emailInputText,
    setEmailInputText,

    errorMessage,
    setErrorMessage,

    isLoading,
    setIsLoading,

    handleSubscribeClick,

    hasSuccessfullySubscribed,
  }
}