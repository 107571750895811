import React from 'react'
import PropTypes from 'prop-types'

import { useEmailSubscribeForm } from './use-email-susbscribe-form-hook'
import * as S from './email-subscribe-form-styled'

const EmailSubscribeFormComponent = ({ apiUrl, showLabel }) => {
  const {
    emailInputText,
    setEmailInputText,
    errorMessage,
    setErrorMessage,
    isLoading,
    handleSubscribeClick,
    hasSuccessfullySubscribed,
  } = useEmailSubscribeForm({ apiUrl });

  return (
    <S.Container>

      {showLabel && <S.Label>Sign up for updates</S.Label>}

      {!hasSuccessfullySubscribed && (

        <S.EmailInput
          placeholder="e-mail address"
          value={emailInputText}
          onChange={e => setEmailInputText(e.target.value) && setErrorMessage('')}
          onKeyPress={e => e.key === 'Enter' ? handleSubscribeClick() : null}
        />

      )}

      {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}

      {isLoading ? (

        <S.LoadingIcon />

      ) : (

        hasSuccessfullySubscribed ? (
          <S.SuccessButton>
            Check your e-mail to confirm!
          </S.SuccessButton>

        ) : (

          <S.SubscribeButton onClick={handleSubscribeClick}>
            Subscribe
          </S.SubscribeButton>

        )
      )}

    </S.Container>
  )
}

EmailSubscribeFormComponent.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
}

export { EmailSubscribeFormComponent }