import styled from 'styled-components'

import { TextInput } from '../inputs/text-input-styled'
import { Button } from '../inputs/button-styled'
import { LoadingSpinner } from '../loading-spinner/loading-spinner-component'
import { media } from '../../../constants/media'

export const Container = styled.div`
`;

export const ErrorMessage = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: ${props => props.theme.concepts.errorColor};

  margin-bottom: 25px;
  @media ${media.medium.query} {
    margin-bottom: 40px;
  }
`;

export const Label = styled.div`
  margin-top: 80px;
  margin-bottom: 40px;
  @media ${media.medium.query} {
    margin-top: 120px;
    margin-bottom: 50px;
  }

  color: ${props => props.theme.colors.nori};
  font-family: ${props => props.theme.fonts.solway};
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.2rem;
  letter-spacing: 0.048rem;
  @media ${media.medium.query} {
    font-size: 6rem;
    line-height: 6.1rem;
    letter-spacing: 0.07rem;
  }
`;

export const EmailInput = styled(TextInput)`
  margin-bottom: 25px;
  @media ${media.medium.query} {
    margin-bottom: 40px;
  }
`;

export const SubscribeButton = styled(Button)`
`;

export const LoadingIcon = styled(LoadingSpinner)`
`;

export const SuccessButton = styled(Button)`
  background-color: ${props => props.theme.concepts.disabledColor};
  cursor: not-allowed;
`;